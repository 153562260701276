// src/components/ProductSearch.js
import React, { useState, useEffect } from 'react';
import { TextField, Container, Typography, Box, Card, CardContent, CardMedia, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

// Colores personalizados
const theme = {
  primary: '#1e88e5', // Azul profundo
  secondary: '#ff7043', // Naranja suave
  searchBackground: '#e3f2fd', // Azul muy claro para el fondo de búsqueda
  priceText: '#c2185b', // Rosa oscuro para el precio
  background: '#fafafa', // Fondo general más claro
  textPrimary: '#424242', // Texto principal oscuro
};

// Crear un TextField estilizado
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px', // Bordes redondeados
    backgroundColor: theme.searchBackground, // Fondo del campo de búsqueda
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.primary, // Color del borde cuando está enfocado
  },
}));

const ProductSearch = () => {
  const [barcode, setBarcode] = useState('');
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

   
  useEffect(() => {
    let timeoutId;

    if (loading) {
      timeoutId = setTimeout(() => {
        setBarcode('');
        setLoading(false);
        setProduct(null);
      }, 40000);   
    }

    return () => clearTimeout(timeoutId); // Limpia el timeout si el componente se desmonta
  }, [loading]);

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      setLoading(true);
      try {
        // Reemplaza con la URL de tu API 
        const response = await fetch(`https://api.corporacionpumac.com/v1/productos/barcode/${barcode}`);
        const data = await response.json();

        if (data) {
          setProduct(data);
        } else {
          // Datos ficticios para el producto cuando no se encuentra uno en la API
          const fakeProduct = {
            name: 'Producto Ejemplo',
            price: 19.99,
            image: 'https://via.placeholder.com/300', // Imagen de muestra
          };
          setProduct(fakeProduct);
        }
      } catch (error) {
        console.error('Error al buscar el producto:', error);
        // Datos ficticios para el producto en caso de error en la API
        const fakeProduct = {
          name: 'Producto Ejemplo',
          price: 19.99,
          image: 'https://via.placeholder.com/300', // Imagen de muestra
        };
        setProduct(fakeProduct);
      }

      // Limpia la caja de texto después de procesar la búsqueda
      setBarcode('');
      setTimeout(() => {
        setLoading(false);
      }, 800);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px', backgroundColor: theme.background, padding: '20px', borderRadius: '12px' }}>
      <Box textAlign="center">
        <Typography variant="h4" component="h1" gutterBottom style={{ color: theme.primary, marginTop: 0 }}>
          BUSCAR PRODUCTOS
        </Typography>
        <CustomTextField
          label="Código de Barras"
          variant="outlined"
          fullWidth
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
          onKeyPress={handleKeyPress}
          margin="normal"
          autoFocus
        />
        {loading ? (
            <CircularProgress  style={{ marginTop: '20px' }} />
        ) : (
          product && (
            <Card elevation={3} style={{ marginTop: '20px', textAlign: 'center', backgroundColor: '#fff', borderRadius: '12px' }}>
              <CardMedia
                component="img"
                alt={product.nombre}
                height="300"
                image={product.imagen}
                title={product.nombre}
                style={{ borderRadius: '12px' }}
              />
              <CardContent>
                <Typography variant="h4" component="h2" style={{ color: theme.textPrimary, marginTop: 0 }}>
                  {product.nombre}
                </Typography>
                <Typography variant="h4" component="h3" style={{ color: theme.priceText, marginTop: 0 }}>
                  Precio: S/.{product.precio}
                </Typography>
              </CardContent>
            </Card>
          )
        )}
      </Box>
    </Container>
  );
};

export default ProductSearch;

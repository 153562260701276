//import logo from './logo.svg';
//import './App.css';
 
import React from 'react'; 
import ProductSearch  from './components/ProductSearch';
import { CssBaseline } from '@mui/material';

function App() {
  return (
    <>
      <CssBaseline />
      <ProductSearch />
    </>
  );
}

export default App;
